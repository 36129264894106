/* ----------------------------------------------------------------------------
 * Structure
 * ------------------------------------------------------------------------- */

/* global box-sizing */
*,
*:after,
*:before {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
}
:root {
    overflow-y: initial;
}
/* html element 62.5% font-size for REM use i.e. 1rem = 10px*/
html {
    font-size: 62.5%;
}

body {
    font: normal 15px/1.4 $fontface1;
    /*font-size: calc(0.75em + 1vmin);*/
    color: $white;
    margin: 0;
    padding: 0;
    background-color: $gray-dark;
    @include r(400) {
        font-size: 16px;
    }
    @include r(1024) {
        overflow: hidden;
    }
    @include r(1500) {
        font-size: 16px;
    }
    @media screen and (min-width: 1024px) and (max-height: 770px) {
        font-size: 14px;
    }
}
html, body {
    height: 100%;
}
/* clear */
.clear:before,
.clear:after {
    content: ' ';
    display: table;
}

.clear:after {
    clear: both;
}

.clear {
    *zoom: 1;
}

img {
    max-width: 100%;
    vertical-align: bottom;
    height: auto;
}

a {
    color: $black;
    text-decoration: none;
}


a:focus {
    outline: 0;
}

a:hover,
a:active {
    outline: 0;
}

input:focus {
    outline: 0;
    border: 1px solid $color2;
}

ol, ul {

}
select{
    width: 100%;
}
p {
    margin-bottom: 1em;
}

address {
    font-style: normal;
}