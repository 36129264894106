.sections-wrapper {
    /* background-color: $gray-dark; */
    -webkit-overflow-scrolling: touch;
    overflow: scroll;
    height: 100%;
    transition: none;
    /*will-change: transform;*/
    @include r(1024) {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        bottom: 0;
        white-space: nowrap;
        overflow: hidden;
        word-spacing:-.5em;
    }
}

.sections-wrapper__xscroll {
    @include r(1024) {
        overflow-x: auto;
    }
}
.sections-inner {
    height: 100%;
    @include r(1024) {
        height: 100vh;
    }
}


/* Colorize based on page */

@mixin theme-common-elements($themecolor) {
    color: $themecolor;
    .header__logo svg,
    .section__logo svg {
        fill: $themecolor;
    }
    .section__category {
        border-color: $themecolor;
    }
    .section-next__title,
    .section-next__description {
        color: $themecolor;
    }
    .section-next__title:before,
    .section-next__title:after,
    .section-next__description:after {
        background-color: $themecolor;
    }
}

.home,
.single-project {
    @include theme-common-elements($black);
}
.company {
    @include theme-common-elements($pink);
}
.approach {
    @include theme-common-elements($blue);
}
.news {
    @include theme-common-elements($green);
}

.contact {
    @include theme-common-elements($black);
    //Extra
    .sections-wrapper {
        &.color-rotation {
            @include animation('color-rotation 10s linear 0ms infinite');
        }
    }
}
.legal {
    @include theme-common-elements($pink);
}
.error404 {
    @include theme-common-elements($green);
}
