
.contact {
	background: $blue;
}
.section-halfimage {
	.contact & a {
		color: $black;
		font-family: $fontface2;
		text-transform: uppercase;
		font-size: 13px;
		letter-spacing: .075em;
	}
	.contact-info {
		display: flex;
		.col:first-child {
			margin-right: 40px;
			p:first-child a {
				@include r(1024) {
					display: none;
				}
			}
		}
	}
	.col {
		flex: 1;
	}
	.col--bg {
		padding-bottom: 130%;
		margin-top: 60px;
	}
	@include r(1024) {
		width: 100%;
		max-width: 100%;
		.col {
			height: auto;
			display: block;
		}
		.section__content {
			flex: 1;
			align-items: center;
			padding-left: 6vw;
			padding-right: 6vw;
		}
		.col--bg {
			height: calc(100vh - 2 * 90px);
			padding-bottom: 0;
			margin-top: 0;
		}
		.contact-info {
			max-width: 400px;
			font-size: 15px;
		}
		
	}
}
