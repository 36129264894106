/*$themecolor : $green;*/

.section-home {
	@include r(1024) {
		width: 100%;
		overflow: hidden;
	}
	.cols {
		flex: 1;
		@include r(1024) {
			width: 100%;
			height: 100vh;
		}
	}
	.col {
		@include r(1024) {
			display: flex;
			align-items: flex-end;
			flex-direction: row;
		}
	}
	.col__inner {
		@include r(1024) {
			padding: 0 6vw;
			margin-bottom: 40vh;
		}
	}
	.col--bg {
		padding-bottom: 65%;
		@include r(1024) {
			margin-bottom: 0;
			padding-bottom: 0;
		}
	}
	.section__intro {
		// max-width: 400px;
		@include r(1024) {
			position: absolute;
			width: 40vmin;
		}
	}

	.home-slider {
		width: 100%;
		position: relative;
		border: 0;
		height: auto;
		@include r(1024) {
			height: 100%;
		}
	}
	/*auto height on slides*/
	.slick-track {
		height: auto;
		@include r(1024) {
	    	display: flex;
	    	height: 100%;
	    	.slick-slide {
		        display: flex;
		        height: auto;
		        align-items: center; 
		        justify-content: center; 
		    }
		}
	    
	}
	.slide {
		padding-top: 120px;
		padding-bottom: 70px;
		height: auto;
		@include r(1024) {
			padding: 0;
			height: 100%;
		}
	}
	.slide__link {
		display: none;
		position: absolute;
		top: 50%;
		left: calc(50% + 3vw );
		border-radius: 50%;
		height: 80px;
		width: 80px;
		align-items: center;
		justify-content: center;
		font-family: $fontface3;
		font-size: 13px;
		text-transform: uppercase;
		letter-spacing: .075em;
		color: $black;
		transition: all .5s ease-in-out;
		cursor: pointer;
		@include r(1024) {
			display: flex;
			&:before {
				content: '';
				display: block;
				position: absolute;
				z-index: -1;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				border-radius: 50%;
				transition: all .5s ease-in-out;
				transform-origin: 50% 50%;
			}
		}
		
		&:hover {
			@include r(1024) {
				&:before {
					transform: scale(1.15);
					background: $white;
				}
			}
		}
	}
	.slider__buttons {
		position: absolute;
		bottom: 15px;
		top: auto;
		left: 25px;
		padding: 0;
		@include r(1024) {
			position: absolute;
			z-index: 1;
			top: auto;
			bottom: 5vh;
			left: 6vw;
			padding: 0;
		}
		&:after {
			content: '';
			display: table;
			clear: both;
		}

	}
	
	.slider__button {
		border: 1px solid rgba($black, .5);
		transition: all .5s ease-in-out;
		box-shadow: inset 0 0 0 0px $black;
		&:hover {
			@include r(1024) {
				box-shadow: inset 0 0 0 2px $black;
			}
		}
	}
   	.slider__button,
   	.slick-arrow,
   	.slider__button:after {
		background: transparent;
   	}
}

.home-slides {
	display: block;
	height: auto;
	.col__inner {
		padding-top: 0;
	}
	.section__title {
		height: 2em;
		display: flex;
		align-items: flex-end;
		margin-bottom: 0
	}
	.slide {
		min-height: 100vmax;
		display: flex;
		align-items: flex-end;
	}
	@include r(1024) {
		display: none;
	}
}
.projects__toggle-wrapper {
	.projects-open &:before {
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		right: 50%;
		left: 0;
		display: block;
		z-index: 1;
	}
}
.projects__toggle {
	display: none;
	position: absolute;
	z-index: 3;
	bottom: calc(5vh + 10px);
	right: calc(50vw + 5vw);
	font-family: $fontface3;
	text-transform: uppercase;
	letter-spacing: .05em;
	cursor: pointer;
	@include r(1024) {
		display: block;
	}
	span {
		&:first-child {
			display: block;
		}
		&:last-child {
			display: none;
		}
	}
	.projects-open & {
		span {
			pointer-events: all;
			&:first-child {
				display: none;
			}
			&:last-child {
				display: block;
			}
		}
	}
}

.home {
	@include r(1024) {
		overflow: hidden;
	}
	.sections-wrapper {
		overflow: auto;
		height: 100%;
		position: relative;
		@include r(1024) {
			overflow: hidden;
		}
	}
	.section-logo {
		/*background: $themecolor;*/
		position: absolute;
		z-index: 2;
	}
	.section-home {
		@include r(1024) {
			padding-top: 0;
		}
	}
}

.home__projects {
	@include r(1024) {
		position: absolute;
		z-index: 3;
		width: 50%;
		height: 100%;
		right: 0;
		top: 0;
		opacity: 0;
		visibility: hidden;
		transition: opacity .5s ease-in-out;
		transform: translate3d(0, 0, 0);
	}
	&--open {
		@include r(1024) {
			opacity: 1;
			visibility: inherit;
			overflow: auto;
		}
	}

}


.projects-open {
	.nav__projects {
		opacity: 1;
		visibility: inherit;
		overflow: auto;
	}
    .nav__project {
    	opacity: 1;
        transform: translate3d(0, 0, 0);
    }
    .panel .section__title,
    .panel .section__intro {
		opacity: .2 !important;
		transition: opacity .5s ease-in-out;
    }
    .section-logo,
    .slider__buttons {
    	opacity: .2;
    	pointer-events: none;
    	transition: opacity .5s ease-in-out;
    }

}


@mixin slide-link-color($themecolor) {
    background: $themecolor;
    &:before {
    	background: $themecolor;
    }
}
.slide__link--green {
	@include slide-link-color($green);
}
.slide__link--blue {
	@include slide-link-color($blue);
}
.slide__link--pink {
	@include slide-link-color($pink);
}

@mixin theme-slide($themecolor) {
    background-color: $themecolor;
    .slide__link {
    	background: $themecolor;
    	box-shadow: 0 0 0 0px $themecolor;
    }
}
.slide--green,
.pic--green {
	@include theme-slide($green);
}
.slide--blue,
.pic--blue {
	@include theme-slide($blue);
}
.slide--pink,
.pic--pink {
	@include theme-slide($pink);
}

