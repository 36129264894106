@font-face {
    font-family: 'Mercury Display';
    src: url('webfonts/MercuryDisplay-Roman.eot');
    src: url('webfonts/MercuryDisplay-Roman.eot?#iefix') format('embedded-opentype'),
        url('webfonts/MercuryDisplay-Roman.woff') format('woff'),
        url('webfonts/MercuryDisplay-Roman.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Mercury Text G1';
    src: url('webfonts/MercuryTextG1-Roman.eot');
    src: url('webfonts/MercuryTextG1-Roman.eot?#iefix') format('embedded-opentype'), url('webfonts/MercuryTextG1-Roman.woff2') format('woff2'), url('webfonts/MercuryTextG1-Roman.woff') format('woff'), url('webfonts/MercuryTextG1-Roman.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Mercury Text G1';
    src: url('webfonts/MercuryTextG1-Italic.eot');
    src: url('webfonts/MercuryTextG1-Italic.eot?#iefix') format('embedded-opentype'), url('webfonts/MercuryTextG1-Italic.woff2') format('woff2'), url('webfonts/MercuryTextG1-Italic.woff') format('woff'), url('webfonts/MercuryTextG1-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Whitney';
    src: url('webfonts/Whitney-Semibold.eot');
    src: url('webfonts/Whitney-Semibold.eot?#iefix') format('embedded-opentype'), url('webfonts/Whitney-Semibold.woff2') format('woff2'), url('webfonts/Whitney-Semibold.woff') format('woff'), url('webfonts/Whitney-Semibold.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Whitney Narrow';
    src: url('webfonts/WhitneyNarrow-Semibold.eot');
    src: url('webfonts/WhitneyNarrow-Semibold.eot?#iefix') format('embedded-opentype'), url('webfonts/WhitneyNarrow-Semibold.woff2') format('woff2'), url('webfonts/WhitneyNarrow-Semibold.woff') format('woff'), url('webfonts/WhitneyNarrow-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}