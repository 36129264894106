.single-project {
	color: $gray-dark;
	&.theme--green {
		background: $green;
	}
	&.theme--blue {
		background: $blue;
	}
	&.theme--pink {
		background: $pink;
	}
	@include r(1024) {
		.section-logo {
			background: transparent;
			svg {
				fill: $black;
			}
		}
	}
	.section {
		margin-bottom: 0;
		a {
			/*color: $black;*/
		}
	}
	.slider {
		border: 0;
	}
	.section-next {
		a {
			color: $black;
		}
		.section__content {
			padding-top: 100px;
		}
		@include r(1024) {
			.section__content {
				padding-top: 90px;
				padding-left: 20vw;
			}
		}
	}
	.cols--bordered {
		.col--right {
			border-width: 0;
			@include r(1024) {
				border-width: 1px;
			}
			/*border-color: transparent;*/
		}
	}
	.col--narrow {
		padding-top: 12vmin;
		padding-bottom: 6vmin;
		@include r(1024) {
			padding-top: 0;
			padding-bottom: 0;
			display: flex;
		}
	}
}

.project {}


/* Common Components */

.project__label {
	font-family: $fontface2;
	font-size: 13px;
	font-weight: normal;
	text-transform: uppercase;
	letter-spacing: .075em;
}


/* Project: Intro */

.project-intro {
	a {
		color: $black;
	}
	@include r(1024) {
		min-width: 450px;
		.section__content {
			padding: 90px 6vw;
		}
	}
}

.project-intro__title {
	font-family: $fontface1;
	font-weight: normal;
	font-size: 14vmin;
	letter-spacing: -.02em;
	line-height: 1;
	padding-top: 80px;
	@include r(1024) {
		padding-top: 0;
		font-size: 9vmin;
	}
}

.project-intro__subtitle {
	font-style: italic;
	letter-spacing: -.01em;
	font-size: 5vmin;
	line-height: 1.25;

	margin-bottom: 20px;
	&:before {
		// content: "–";
	}
	@include r(400) {
		font-size: 3.75vmin;
		
	}
	@include r(1024) {
		font-size: 24px;
		line-height: 1.4;
	}
}

.project-intro__text {
	font-family: $fontface3;
	margin-bottom: 20px;
	@include r(1024) {
		width: 35vmin;
	}
	&>:last-child {
		margin-bottom: 0;
		
	}
}

.image--map {
	text-align: center;
	margin-bottom: 25px;
}


/* Project: Image */

.project-image {
	@include r(1024) {
		overflow: hidden;
		width: 110vw;
		height: 100vh;
		z-index: 0;
	}
}

.project-image__container {
	@include r(1024) {
		background-size: cover;
		background-position: 50% 50%;
		background-repeat: no-repeat;
		width: 110vw;
		height: 100vh;
	}
}

.project-image__image {
	@include r(1024) {
		display: none;
	}
}

.image--zoom {
	@extend .project-image__image; // transition: transform .3s linear;
	// transform-origin: 100% 100%;
	display: block;
	@include r(400) {

	}
	@include r(1025) {
		display: block;
		transform: translate3d(-100%, 0, 0);
	}
	img {
		display: block;
		@include r(1024) {
			display: none;
		}
	}
}


/* Project: Neighborhood */

.neighborhood__info {
	@include r(1024) {}
}

.neighborhood__image {
	display: none;
	@include r(1024) {
		display: block;
		margin-top: auto;
	}
	img {
		display: block;
		border-radius: 50%;
		@include r(400) {
			max-width: 36vmax;
		}
		@media screen and (min-height: 400px) and (max-height: 680px) {
			display: none;
		}
		@media screen and (min-width: 1024px) and (max-height: 860px) {
			max-height: 30vh;
		}
		@media screen and (min-width: 1024px) and (min-height: 860px) {
			max-width: 100%;
		}

	}
	.image-col+& {
		margin-top: 60px;
	}
}

.image--large,
.image--small {
	background-size: cover;
}

.image--large {
	width: 100%;
	padding-bottom: calc(100% - 10px);
	margin-bottom: 10px;
	@include r(1024) {
		height: calc(100vh - 180px);
		width: calc(100vh - 180px);
		padding-bottom: 0;
		margin-bottom: 0;
		margin-right: 10px;
	}
}

.image--small {
	width: 100%;
	padding-bottom: calc(50% - 10px);
	margin-bottom: 10px;
	&:first-child {
		margin-right: 10px;
	}
	@include r(1024) {
		height: calc(50vh - 90px);
		width: calc(50vh - 90px);
		padding-bottom: 0;
		margin-right: 10px;
		&:last-child {
			margin-bottom: 0;
		}
	}
}

.col-images {
	display: flex;
	flex-direction: column;
	@include r(1024) {
		flex-direction: row;
		flex-wrap: nowrap;
	}
}

.image-col {
	display: flex;
	@include r(1024) {
		flex-direction: column;
	}
}


/* Project: Contributor */

.project-contributor {
	background: $gray-dark;
	padding-top: 6vmin;
	padding-bottom: 6vmin;
	@include r(1024) {
		padding-top: 0;
		padding-bottom: 0;
	}
	.section__content {}
	.col--left {
		@include r(1024) {
			width: 350px;
			display: flex;
			flex-direction: column;
			border: 0;
		}
	}
	.col--right {}
}


/* Project: Features */

.project-features {
	.col--pad {
		@include r(1024) {
			margin-left: 6vw;
		}
	}
}

.feature-image__thumbnail {
	text-align: center;
	display: none;
	img {
		/*margin: auto;*/
		display: block;
		border-radius: 50%;
		max-width: 100%;
		@include r(400) {
			max-width: 36vmax;
		}
		@media screen and (min-height: 400px) and (max-height: 680px) {
			display: none;
		}
		@media screen and (min-width: 1024px) and (max-height: 860px) {
			max-height: 30vh;
		}
		@media screen and (min-width: 1024px) and (min-height: 860px) {
			max-width: 100%;
		}
	}
	@include r(1024) {
		position: absolute;
		bottom: 0;
		display: block;
	}
}

.neighborhood__image--mobile,
.feature-image--mobile {
	display: block;
	margin-top: 6vmin;
	/*margin-bottom: 6vmin;*/
	@include r(1024) {
		display: none;
		max-width: 100%;
	}
	img {
		@include r(400) {
			max-width: 36vmax;
		}
		@include r(1024) {
			max-width: 100%;
		}
	}
}

.feature-image__large {
	@include r(1024) {
		height: calc(100vh - 180px);
		width: auto;
		max-width: none;
	}
}

@mixin section-dark($themecolor) {
	background: $gray-dark;
	@include theme-slider($themecolor);
	.cols--bordered .col {
		border-color: shade($themecolor, 60%);
		.single-project &.col--right {
			border-color: transparent;
			@include r(1024) {
				border-color: shade($themecolor, 60%);
			}
		}
	}
	.contributor__link:before,
	.contributor__link:hover:after {
		background: $themecolor
	}
}

.theme--green {
	.section {
		background: $green;
	}
	.section--dark {
		@include section-dark($green);
	}
}

.theme--blue {
	.section {
		background: $blue;
	}
	.section--dark {
		@include section-dark($blue);
	}
}

.theme--pink {
	.section {
		background: $pink;
	}
	.section--dark {
		@include section-dark($pink);
	}
}