/* ----------------------------------------------------------------------------
 * Mixins
 * ------------------------------------------------------------------------- */

/*
 * Mixin for clearfix
 * @include clearfix;
*/
@mixin clearfix {
	&:before,
	&:after {
        content: " ";
        display: table;
    }

	&:after {
        clear: both;
    }
}


/*
 * @font-face mixin
 * Bulletproof font-face via Font Squirrel
 * @include fontface('family', 'assets/fonts/', 'myfontname');
 */
@mixin fontface($font-family, $font-url, $font-name) {
	@font-face {
        font: {
			family: $font-family;
        style: normal;
        weight: normal;
    }

    src: url($font-url + '/' + $font-name + '.eot');
		src: url($font-url + '/' + $font-name + '.eot#iefix') format('embedded-opentype'),
			 url($font-url + '/' + $font-name + '.woff') format('woff'),
			 url($font-url + '/' + $font-name + '.ttf')  format('truetype'),
			 url($font-url + '/' + $font-name + '.svg#' + $font-name) format('svg');
  }
}


/**
 * IMAGE RETINA
 * @include image-2x(/img/image.png, 100%, auto);
 */
@mixin image-2x($image, $width, $height) {
  @media (min--moz-device-pixel-ratio: 1.3),
         (-o-min-device-pixel-ratio: 2.6/2),
         (-webkit-min-device-pixel-ratio: 1.3),
         (min-device-pixel-ratio: 1.3),
         (min-resolution: 1.3dppx) {
    background-image: url($image);
    background-size: $width $height;
  }
}


/* ----------------------------------------------------------------------------
 * Responsive
 * Usage

    @include r(240)  { }
    @include r(320)  { }
    @include r(480)  { }
    @include r(640)  { }
    @include r(768)  { }
    @include r(1024) { }
    @include r(1280) { }

 * ------------------------------------------------------------------------- */

 @mixin r($point) {
    @media (min-width: $point + "px") { @content; }
 }



/**
@include keyframes(move-the-object) {
  0%   { left: 100px; }
  100% { left: 200px; }
}
*/
 @mixin keyframes($animationName) {
    @-webkit-keyframes #{$animationName} {
        @content;
    }
    @-moz-keyframes #{$animationName} {
        @content;
    }
    @-o-keyframes #{$animationName} {
        @content;
    }
    @keyframes #{$animationName} {
        @content;
    }
}

/**
.object-to-animate {
  @include animation('move-the-object .5s 1', 'move-the-object-again .5s 1 .5s');
} 
*/
@mixin animation($animate...) {
    $max: length($animate);
    $animations: '';

    @for $i from 1 through $max {
        $animations: #{$animations + nth($animate, $i)};

        @if $i < $max {
            $animations: #{$animations + ", "};
        }
    }
    -webkit-animation: $animations;
    -moz-animation:    $animations;
    -o-animation:      $animations;
    animation:         $animations;
}


@function delay($counter, $between, $start){
  @return $counter * $between + $start;
}

/// Slightly lighten a color
/// @access public
/// @param {Color} $color - color to tint
/// @param {Number} $percentage - percentage of `$color` in returned color
/// @return {Color}
@function tint($color, $percentage) {
  @return mix(white, $color, $percentage);
}

/// Slightly darken a color
/// @access public
/// @param {Color} $color - color to shade
/// @param {Number} $percentage - percentage of `$color` in returned color
/// @return {Color}
@function shade($color, $percentage) {
  @return mix(black, $color, $percentage);
}