.panels {
    display: none;
    @include r(1024) {
        display: block;
    }
}
#panels {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    transform: translate3d(0,0,0);
}

#pics {
    width: calc(50% + 2px);
    height: 100%;
    left: 50%;
    position: absolute;
    overflow: hidden;
}
.pic-holder {
    height: 100%;
    position: absolute;
}

.panel {
	width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    overflow: hidden;
    transform: translate3d(0,0,0);
    &--pink {
        background: $pink;
    }
    &--blue {
        background: $blue;
    }
    &--green {
        background: $green;
    }
}

.pic {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
}
