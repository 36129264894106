@include keyframes(hue-rotation) {
  0%   { filter: hue-rotate(0) }
  100% { filter: hue-rotate(360deg) }
}

@include keyframes(color-rotation) {
  0%   { background: $blue }
  27%  { background: $blue }
  33%  { background: $pink }
  60%  { background: $pink }
  66%  { background: $green }
  93%  { background: $green }
  100% { background: $blue }
}
@include keyframes(arrow-move) {
  0% {
    transform: scaleX(0) translateX(0%);
  }
  30% { 
    transform: scaleX(1) translateX(100%);
  }
  50% {
    transform: scaleX(0) translateX(-100%);
    opacity: 1;
  }
  50% {
    transform: scaleX(0)  translateX(-100%);
    opacity: 0;
  }
  50% {
    transform: scaleX(0)  translateX(-100%);
    opacity: 1;
  }
  100% {
    transform: scaleX(1) translateX(0%);
  }
}

@include keyframes(sliderbar) {
    0%   { height: 0%; }
    100% { height: 100% }
}


/* Moving underline on hover */
@mixin movingline($themename, $themecolor:$gray-dark) {
    .movingline,
    .movingline--#{$themename} {
        position: relative;
        &:after,
        &:before {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            height: 1px;
            width: 100%;
            bottom: -6px;
            background: $themecolor;
            transition: width .5s ease;

        }
        &:before {
            opacity: .3;
        }
        &:after {
            width: 0;
            opacity: 1;
        }
       
        &:hover:after {
            @include r(1024) {
                width: 100%;
            }
        }
    }
}
@include movingline(white, $white) ;
@include movingline(pink, $pink) ;
@include movingline(blue, $blue) ;
@include movingline(green, $green) ;
@include movingline(black, $gray-dark) ;

