/* ----------------------------------------------------------------------------
 * Variables
 * ------------------------------------------------------------------------- */

/* ----------------------------------------------------------------------------
 * Colors
 * ------------------------------------------------------------------------- */

$color1 		: #dedede;
$color2 		: #292929;
$white		    : #ffffff;
$black			: #000000;
$gray-dark	    : #303030;
$gray-light     : #818181;
$green          : #ccdec7;
$pink           : #d5b2a7;
$blue		    : #b5d1d7;


$fontface1      : 'Mercury Text G1', Times, serif;
$fontface2 		: 'Whitney', "Times New Roman", Arial, Helvetica, sans-serif;
$fontface3 		: 'Whitney Narrow', "Times New Roman", Arial, Helvetica, sans-serif;
$fontface4      : 'Mercury Display', Times, serif;

$mobile-padding : 6vmin;
$desktop-padding: 6vmin;