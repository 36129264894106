$themecolor: $pink;

.legal {

	
	.section-halfimage .col:nth-child(2) {
		height: 100%;
		border: 1px solid shade($themecolor, 60%)
	}
	.scroll-content {
		padding: 5vmin;

		a {
			color: $themecolor;
		}
	}

	.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
		background: $themecolor;
	}
	.mCS-minimal.mCSB_scrollTools .mCSB_draggerRail, 
	.mCS-minimal-dark.mCSB_scrollTools .mCSB_draggerRail {
		background: rgba($themecolor, .1)
	}

	h3 {
		@extend .contributor__label;
		margin-bottom: .35em
	}

}

