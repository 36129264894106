$themecolor : $green;

.error404 {
    .section-halfimage {
        .error404__link {
            color: $themecolor;
        }
    }

    .section__title {
        @include r(1024) {
            font-size: 20vmin;
        }
    }
}

