body.login {
    background: #303030 url(img/pattern.svg) 50% 50% repeat;
    display: flex;
    align-content: center;
    justify-content: center;
}

#login {
    position: relative;
    width: 100%;
    background: none;
    padding: 0;
    max-width: 400px;
}

.login form {
    margin: 0;
    padding: 10%;
    overflow: hidden;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -ms-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;
    border: 1px solid #525950;
    background: #000;
    color: #ccdec7;
}

.login form:hover {
    opacity: 1
}

#login h1 a {
    width: 100px;
    height: 120px;
    background: url(img/logo-login.svg) no-repeat center center;
    background-size: 100%;
    display: block
}

.wp-core-ui .button-group.button-large .button,
.wp-core-ui .button.button-large {
    margin: 40px auto 15px auto;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -ms-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    float: none;
    display: inline-block;
    height: auto;
    padding: 20px 25px;
    background-color: #525950;
    border: 1px solid #525950;
    text-transform: uppercase;
    font-family: $fontface2;
    font-size: 15px;
    color: #ccdec7;
    // font-weight: 600;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-border-radius: 0px !important;
    -moz-border-radius: 0px !important;
    border-radius: 0px !important;
    box-shadow: none;
}

.wp-core-ui .button-group.button-large .button:hover,
.wp-core-ui .button.button-large:hover {
    background: #ccdec7;
    color: #000;
}
.wp-core-ui .button-primary {
    text-shadow: none;
}

.login form .input, .login input[type=text] {
    border: 1px solid #525950;
    color: #fff;
    font-size: 20px;
    padding: 15px;
    box-shadow: none;
    background: transparent;
}
.login form .input:focus, .login input[type=text]:focus {
    border-color: #ccdec7;
}
.login label {
    color: #ccdec7;
    font-size: 16px;
    font-weight: bold;
}
.login form .forgetmenot label {
    font-size: 14px;
}
.login a {
    color: #ccdec7;
}
.login #backtoblog, .login #nav {
    font-size: 16px;
}
.login #backtoblog a,
.login #nav a {
    color: #ccdec7;
    text-decoration: underline;
}

.login #backtoblog a:hover,
.login #nav a:hover {
    color: #ccdec7;
    text-decoration: none;
}

.login #login_error, .login .message {
    border-left-width: 0px;
    background-color: transparent;
    color: #ccdec7;
}


/*****************************************************
Desktop
*****************************************************/

@media screen and (min-width: 640px) {
    .login form {
        margin: 0px;
    }
    .wp-core-ui .button-group.button-large .button,
    .wp-core-ui .button.button-large {
        margin: 20px auto 0 auto;
    }
}