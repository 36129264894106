.slider {
	height: 100%;
	position: relative;
	@include r(1024) {
		border: 1px solid;
		width: 130vh;
		height: calc(100vh - 180px);
	}
}

.slide {}

.slider__buttons {
	padding: 0 0 20px;
	z-index: 1;
	&:after {
		content: '';
		display: table;
		clear: both;
	}
	@include r(1024) {
		position: absolute;
		top: 50px;
		left: 70px;
	}
}

.slider__button {
	width: 50px;
	height: 50px;
	color: $black;
	border-radius: 50%;
	line-height: 50px;
	text-align: center;
	cursor: pointer;
	transform-origin: 50% 50%;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
	float: left;
	position: relative;
	svg {
		width: 26px;
		transform-origin: 0 0;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate3d(-50%, -50%, 0);
		transition: none;
		&:nth-child(1) {
			transform: translate3d(-55px, -50%, 0);
		}
		&:nth-child(2) {}
	}
	&:hover {
		@include r(1024) {
			svg {
				transition: all .3s ease-in-out;
				&:nth-child(1) {
					transform: translate3d(-50%, -50%, 0);
				}
				&:nth-child(2) {
					transform: translate3d(55px, -50%, 0);
				}
			}
		}
	}
}

.slider__button--prev {
	transform: scaleX(-1);
	transform-origin: 50% 50%;
}

.slider__button--next {
	margin-left: 20px;
}

.slide-cols {
	@include r(1024) {
		display: flex;
		/*width: 100%;*/
		height: 100%;
		align-items: center;
		background-size: 21vmax 17vmax;
	}
}

.slide-col {
	&:first-child {
		border: 1px solid $white;
		background-size: 21vmax 17vmax;
		@include r(1024) {
			order: 2;
			background: none;
			flex: 1;
			border: 0;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
	&:last-child {
		position: relative;
		padding-top: 30px;
		@include r(1024) {
			order: 1;
			flex: 0 0 350px;
			height: 100%;
			display: flex;
			flex-direction: column;
			padding-top: 25vh;
			padding-bottom: 0;
		}
	}
}

.slide-col__image {
	padding: 80px 6vmin;
	@include r(1024) {
		padding: 80px 30px;
	}
}

.slide-col__info {
	@include r(1024) {
		padding: 70px 20px 70px 70px;
	}
}

.slide__image {
	margin: auto;
	/*width: 100%;*/
}

.slide__section {
	@extend .contributor__name;
}

.slide__title {
	@extend .contributor__label;
}

.slide__text {
	margin-bottom: 0;
}

.slider__links {
	display: none;
	position: absolute;
	z-index: 1;
	top: 64px;
	left: 250px;
	right: 0;
	text-align: center;
	@include r(1024) {
		display: block;
	}
}

.slider__link {
	list-style: none;
	font-family: $fontface3;
	font-size: 15px;
	text-transform: uppercase;
	letter-spacing: .075em;
	display: inline-block;
	margin: 0 20px;
	opacity: .4;
	transition: .5s opacity ease-in-out;
	&--active,
	&:focus {
		opacity: 1;
		cursor: pointer;
	}
	&:hover {
		@include r(1024) {
			opacity: 1;
			cursor: pointer;
		}
	}
}

.slider__bars {
	display: none;
	@include r(1024) {
		display: flex;
		background: $white;
		width: 5px;
		height: 100%;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 50%;
		z-index: 2;
		flex-direction: column;
	}
}

.slider__bar {
	width: 100%;
	flex: 1;
	border-bottom: 2px solid $gray-light;
	position: relative;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: -10px;
		right: -10px;
		bottom: 0;
	}
	&:hover {
		@include r(1024) {
			cursor: pointer;
			span {
				opacity: 1;
				transform: translateX(-100%);
			}
		}
	}
	span {
		opacity: 0;
		font-family: $fontface2;
		font-size: 10px;
		padding: 0 10px;
		transform: translateX(-50%);
		transition: all .5s ease-in-out;
	}
	.slider__fill {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 0%;
		opacity: 0;
		background: $black;
		transition-property: opacity;
		transition-timing-function: linear;
	}
}

.slider__bar--prevactive {
	.slider__fill {
		height: 100%;
		opacity: 1;
		transition: none;
	}
}

.slider__bar--active {
	.slider__fill {
		height: 100%;
		opacity: 1;
		transition-property: height;
		transition-timing-function: linear;
	}
}

//Set colors
@mixin theme-slider($themecolor) {
	color: $themecolor;
	border-color: rgba($themecolor, .3);
	.slide-col {
		&:first-child {
			border-color: rgba($themecolor, .3);
		}
	}
	.slider__button,
	.slick-arrow,
	.slider__button:after {
		background: $themecolor;
	}
}

.theme--blue {
	.slide-cols {
		@include r(1024) {
			background-image: url(img/pattern_blue.svg);
		}
	}
	.slide-col {
		&:first-child {
			background-image: url(img/pattern_blue.svg);
			@include r(1024) {
				background: none;
			}
		}
	}
}

.theme--green {
	.slide-cols {
		@include r(1024) {
			background-image: url(img/pattern_green.svg);
		}
	}
	.slide-col {
		&:first-child {
			background-image: url(img/pattern_green.svg);
			@include r(1024) {
				background: none;
			}
		}
	}
}

.theme--pink,
.company {
	.slide-cols {
		@include r(1024) {
			background-image: url(img/pattern_pink.svg);
		}
	}
	.slide-col {
		&:first-child {
			background-image: url(img/pattern_pink.svg);
			@include r(1024) {
				background: none;
			}
		}
	}
}

.project-contributor,
.company {
	.slick-list {
		height: 100% !important;
	}
	.slide {
		/*height: calc(100vh - 180px);*/
		/*width: 100% !important;*/
	}
	.slide__image {
		/*height: calc(100vh - 180px);*/
		/*max-width: none;*/
		padding: 0%;
		@include r(1024) {
			padding: 5%;
		}
	}
}