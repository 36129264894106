
body.has-popup {
    overflow: hidden;
    .overlay {
        content: '';
        position: fixed;
        z-index: 100;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, .95);
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 1;
    }
}


.popup {
    background: transparent;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1043;
    position: fixed;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0!important;
    -webkit-backface-visibility: hidden;
    opacity: 0;
    z-index: -1;
    -webkit-transition: .3s opacity ease-in-out;
    transition: .3s opacity ease-in-out;

    h2 {
        font-size: 24px;
        margin-bottom: 20px;
    }
    h3 {
        font-size: 20px;
    }
    h4 {
        font-size: 16px
    }
}

.popup--open {
    opacity: 1;
    z-index: 101;
}

.popup-content {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    text-align: left;
    z-index: 1045;
    color: $white;
}

.popup-container {
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    padding: 0;
    box-sizing: border-box;
}
.popup-block {
    padding: 6.25%;
    padding: 60px 25px;
    text-align: left;
    max-width: 800px;
    margin: 0 auto;
    position: relative;
}

.popup-close {
    @extend .nav-control;
    /*position: absolute;*/
    position: fixed;
}