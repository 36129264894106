$themecolor: $blue;

.single-contributor {
	@extend %card-details;
	background: $themecolor;
	.cols--bordered .col {
        border-color: shade($blue, 20%);
    }
    .col--left {
    	padding-bottom: 0;
    	@include r(1024) {
    		padding-bottom: 0;
    	}
    	.contributor__link-holder {
    		display: none;
    		@include r(1024) {
    			display: block;
    		}
    	}
    }
    .col--right {
		background: #acc9d0;
		.contributor__link-holder {
			@include r(1024) {
				display: none;
			}
		}
	}
	.related {
		border-color: shade($themecolor, 20%);
	}
	&.theme--blue {
		background: $blue;
		.cols--bordered .col {
	        border-color: shade($blue, 20%);
	    }
	    .col--right {
			background: shade($blue, 5%);
		}
		.related {
			border-color: shade($blue, 20%);
		}
	}
	&.theme--pink {
		background: $pink;
		.cols--bordered .col {
	        border-color: shade($pink, 20%);
	    }
	    .col--right {
			background: shade($pink, 5);
		}
		.related {
			border-color: shade($pink, 20%);
		}
	}
	&.theme--green {
		background: $green;
		.cols--bordered .col {
	        border-color: shade($green, 20%);
	    }
	    .col--right {
			background: shade($green, 5%);
		}
		.related {
			border-color: shade($green, 20%);
		}
	}
}


// Contributor Card on Project Details and Approach
.card-contributor {
	@extend %card-vertical;
}

.contributor__info {
	padding: $mobile-padding;
	p {
		margin-top: 1em;
	}
	@include r(1024) {
		padding: 50px 50px 25px;
		flex-grow: 1;
	}
}
.contributor__title {
	font-family: $fontface3;
    font-size: 13px;
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: .075em;
    margin-bottom: 5px;
    display: block;

}
.contributor__name {
	font-size: 6.5vmin;
    font-weight: normal;
    line-height: 1.2;
    margin-bottom: 15px;
    display: block;
    @include r(1024) {
		font-size: 2.75vmin;
    }
}
.contributor__label {
	font-family: $fontface2;
    /*font-size: 16px;*/
    font-weight: normal;
    display: block;
    margin-bottom: .1em;
}
.contributor__firm {
	
}
.contributor__project {

}
.contributor__role {
	font-style: italic;
	letter-spacing: -.01em;
}
.contributor__image-holder {
	overflow: hidden;
	background-color: #a1a1a1;
	min-height: 20vh;
	@include r(1024) {
		/*margin: auto 0 auto;*/
		margin-top: 0;
		display: flex;
		flex: 0 1 25vh;
		/*min-height: 25vh;
		max-height: 26vh;*/
	}
	@media screen and (min-width: 1024px) and (max-height: 770px) {
		
		flex-basis: 20vh;
	}
}
.contributor__image {
	display: block;
	margin: auto auto 0;
	@include r(1024) {
		height: 100%;
		width: auto;
		object-fit: cover;
	}
	@media screen and (min-width: 1024px) and (max-height: 770px) {
		height: 100%;
	}
}
.contributor__illustration {
	display: block;
	margin-top: 1em;
}
.contributor__link-holder {
	@extend %card__link-holder;
}
.contributor__link {
	@extend %card__link;
	&:before {
		@include r(1024) {
			
		}
	}
}
.single-contributor .contributor__link {
	@include r(1024) {
		color: $gray-dark;
	}
	&:before {
		@include r(1024) {
			background: $gray-dark;
		}
	}
}
.contributor__bio {
	max-width: 600px;
	h2, h3, h4, b {
		@extend .contributor__label;
		margin-bottom: .35em;
	}
}

