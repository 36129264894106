$themecolor : $blue;

/* Mixing for theming 3 rows graphics */
@mixin theme-graphics($themecolor) {
	.graphics {
		@include r(1024) {
			display: flex;
			flex-direction: column;
			border: 1px solid shade($themecolor, 60%);
			border-left: 0;
			width: 700px;
		}
		@media screen and (min-width: 1024px) and (max-height: 770px) {
		width: 740px;
		}
	}	
	.graphic {
		flex: 1;
		border: 1px solid shade($themecolor, 60%);
		padding: $mobile-padding;
		margin-top: -1px;
		@include r(1024) {
			border: 0;
			border-bottom: 1px solid shade($themecolor, 60%);
			padding: 0;
			margin: 0;
			display: flex;
			align-items: center;
			&:last-child {
				border-bottom: 0;
			}
		}
	}
}
.approach {
	@include theme-graphics($themecolor);
}


.graphic__thumb {
	@include r(1024) {

	}
}
.graphic__image {
	margin-bottom: 6vmin;
	@include r(1024) {
		margin: 0;
		padding: 25px 25px 25px 50px;
	}
	svg,
	img {
		display: block;
		margin: auto;
		max-width: 36vmax;
		border-radius: 50%;
		width: 100%;
		height: auto;
		
		@include r(400) {
			max-width: 20vmin;
		}
		
		@media screen and (min-width: 1024px) and (max-height: 770px) {
	       width: 21vmin;
	    }
	}
}
.graphic__thumb--image {
	border-radius: 50%;
	overflow: hidden;
	display: block;
}
.graphic__info {
	margin: auto;
	@include r(1024) {
		flex: 1;
		padding: 25px;
	}
	p:last-child {
		margin-bottom: 0;
	}
	h3 {
		font-family: $fontface2;
		font-weight: normal;
	}
}


.contributors {
    @include r(1024) {
		height: 100%;
    }
}

.approach {
	.contributor__link-holder {
		background: $themecolor;
		@include r(1024) {
			background: none;
		}
	}
	.contributor__link {
		color: $black;
		@include r(1024) {
			color: $themecolor;
		}
	}
}


.svg-animated .linegrow_1,
.svg-animated .linegrow_2,
.svg-animated .linegrow_3 {
	animation-name: linegrow_draw;
	animation-duration: 2000ms;
	animation-delay: 0;
	animation-timing-function: ease-in-out;
	animation-fill-mode: forwards;
}
.svg-animated .linegrow_2 {
	@include r(1024) {
		animation-delay: 500ms;
	}
}
.svg-animated .linegrow_3 {
	@include r(1024) {
		animation-delay: 1000ms;
	}
}

@keyframes linegrow_draw {
	100%{stroke-dashoffset:0;}
}