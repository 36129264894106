.nav-control {
    position: fixed;
    z-index: 30;
    top: $mobile-padding;
    right: $mobile-padding;
    width: 40px;
    height: 40px;
    padding: 14px 10px;
    overflow: visible;
    @include r(1024) {
        /*top: calc(3.5vw - 20px);*/
        top: 25px;
        /*right: calc(3.5vw - 20px);*/
        right: 35px;
    }
    color: $white;
    cursor: pointer;
    transition: .5s all;
    //Menu Circle
    &:before {
        content: '';
        width: 40px;
        height: 40px;
        background: $black;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        border-radius: 50%;
        transition: .5s all;
        @include r(1024) {
            opacity: .2;
        }
    }
    &:hover:before {
        @include r(1024) {
            opacity: 1;
        }
    }
    //Menu Label
    //Open state
    &:after {
        content: 'Menu';
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateX(-120%) translateY(-50%);
        opacity: 0;
        transition: .5s all;
        padding-right: 10px;
        font-family: $fontface3;
        font-size: 13px;
        letter-spacing: .075em;
        text-transform: uppercase;
        //Close state
        .nav-open & {
            content: 'Close';
        }
    }
    
    &:hover:after {
        @include r(1024) {
            transform: translateX(-100%) translateY(-50%);
            opacity: 1;
        }
    }
}



.single-contributor .nav-control:after,
.single-post .nav-control:after,
.contact .nav-control:after {
    color: $black;
}

.single-contributor.nav-open .nav-control:after,
.single-post.nav-open .nav-control:after,
.contact.nav-open .nav-control:after {
    color: $white;
}


.nav-hamburger {
    transition: .4s transform;
    &>span {
        position: relative;
        display: block;
        width: 100%;
        height: 2px;
        margin-bottom: 3px;
        overflow: hidden;
        background: $white;
        transition: .4s transform;
    }
    //Hover
    .nav-control:hover & {
        @include r(1024) {
            transform: rotate(90deg);
        }
    }
    //Close state (body.nav-open). Burger transforms in X.
    .nav-open & {
        transform: rotate(0);
        &>span:first-child {
            transform: translate3d(0, 5px, 0) rotate3d(0, 0, 1, -45deg);
        }
        &>span:nth-child(2) {
            opacity: 0;
            transform: translate3d(0, 5px, 0) rotate3d(0, 0, 1, 0);
        }
        &>span:last-child {
            transform: translate3d(0, -5px, 0) rotate3d(0, 0, 1, 45deg);
        }
    }
}

.nav {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    opacity: 0;
    visibility: hidden;
    transition: all .5s ease-in-out;
    /*transform: translate3d(-100%, 0, 0);*/
    background: $gray-dark url(img/pattern.svg) 50% 50% repeat;;

    //Menu is open. Body has class .nav-open
    .nav-open & {
        opacity: 1;
        visibility: inherit;
        overflow: auto;
        transform: translate3d(0, 0, 0);
    }
    @include r(1024) {
        display: flex;
    }
}



.nav__info {
    padding: $mobile-padding;
    position: relative;
    /*background: url(img/pattern.svg) 50% 50% repeat;*/
    @include r(1024) {
        background: none;
        flex: 1;
        display: flex;
        flex-direction: column;
        padding: 0;
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            width: 0;
            /*background: url(img/pattern.svg) 50% 50% repeat;*/
            transition: all .5s ease-in-out;
        }
        .nav-open &:before {
            width: 100%;
        }
    }
}

.nav__logo {
    @extend .logo;
    margin: 0;
    @include r(1024) {
        margin: 0;
        position: absolute;
        left: 6vw;
        top: 6vw;
    }
    svg {
        fill: $white;
    }
}

.nav__menu {
    list-style: none;
    position: relative;
    margin: 60px 0 50px;
    a {
        color: $white;
        font-size: 13vmin;
        letter-spacing: -.02em;
        line-height: 1.2;
        transition: opacity .5s ease-in-out;
        display: block;
        padding: 0;
        @include r(1024) {
            font-size: 9vmin;
        }
    }
    @include r(1024) {
        margin: auto auto 20vh 6vw;
        
        &:hover {
            a {
                opacity: .2;
                &:hover {
                    opacity: 1;
                }
            }
        }
    }
}

.nav__footer {
    font-size: 12px;
    padding: 25px $mobile-padding;
    color: $gray-light;
    background: $gray-dark;
    @include r(1024) {
        position: absolute;
        bottom: 45px;
        left: 6vw;
        display: block;
        padding: 0;
    }
    a {
        color: $gray-light;
        &:hover {
            color: $white;
        }
    }
}

.nav__projects {
    background: $gray-dark;
    @include r(1024) {
        flex: 1;
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow-x: hidden;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
    }
}

.nav__projects-title {
    color: $white;
    font-family: $fontface3;
    font-size: 13px;
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: .1em;
    padding: 20px $mobile-padding;
    border-top: 1px solid rgba($white, .1);
    border-bottom: 1px solid rgba($white, .1);
    @include r(1024) {
        display: none;
    }
}

.nav__projects-wrap {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
}

.nav__project {
    color: $white;
    position: relative;
    flex: 1 1 auto;
    display: flex;
    min-height: 125px;
    @include r(1024) {
        min-height: 25vh;
    }
    &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 2;
        height: 1px;
        background: $white;
        opacity: .1;
    }
    &:after {
        content: '';
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: $black;
        opacity: 0;
        transition: opacity .5s ease-in-out;
    }
    
    &:hover:after {
        @include r(1024) {
            opacity: .3;
        }
    }
}

.nav__project-thumb {
    order: 2;
    background: $black;
    background-size: cover;
    background-position: 50% 50%;
    width: 50%;
    min-height: 25vw;
    position: relative;
    z-index: 1;
    overflow: hidden;
    padding-bottom: 25%;
    @include r(1024) {
        order: 1;
        min-width: 25vw;
        min-height: 0;
    }
}

.nav__project-title {
    position: relative;
    z-index: 2;
    flex: 1;
    order: 1;
    display: flex;
    align-items: center;
    padding: 6vmin;
    font-size: 6.5vmin;
    line-height: 1.2;
    @include r(400) {
        font-size: 5.5vmin;
    }
    @include r(1024) {
        order: 2;
        font-size: 2.75vmin;
        padding: 25px;
    }
}

// Nav menu intro animation
@include r(1024) {
    .nav__menu {
        opacity: 0;
        transform: translateY(-10px);
        transition: all .5s ease-in-out;
        .nav-open & {
            transition-delay: .5s;
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }
    .nav__project {
        opacity: 0;
        transform: translate3d(0, -20px, 0);
        transition: all .3s ease-in-out;
        .nav-open & {
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }
    @for $i from 1 through 10 {
        .nav-open .nav__project:nth-child(#{$i}),
        .home__projects .nav__project:nth-child(#{$i}) {
            /*transition-delay: delay(.1s, #{$i}, 1s);*/
        }

    }
}


