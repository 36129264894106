.footer {
	
}
.bam {
    width: 0;
    height: 0;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    background: url(img/power.png) 50% 50% no-repeat;
    background-size: contain;
    pointer-events: none;
    transition: all .6s cubic-bezier(0.68, -0.55, 0.265, 1.55); 
}
.power .bam {
    opacity: 1;
    width: 80%;
    height: 80%;
}