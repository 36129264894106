/**!
Theme Name: Palisades v1.0
Theme URI: http://palisades.com
Description: Palisades.com Wordpress Theme
Version: 1.0
Author: Allis Studio
Author URI: http://allisinc.com
License: GNU General Public License v2 or later
License URI: http://www.gnu.org/licenses/gpl-2.0.html
Tags: allisinc
Text Domain: allis
*/

/* ----------------------------------------------------------------------------
 * Mixins & Variables
 * ------------------------------------------------------------------------- */
@import "base/mixins";
@import "base/variables";

// /* ----------------------------------------------------------------------------
//  * Normalize & Santize
//  * ------------------------------------------------------------------------- */
@import "vendors/normalize";
@import "vendors/sanitize";

// /* Vendors */
@import "vendors/jquery.mCustomScrollbar";
@import "vendors/slick";
@import "vendors/blazy";

// /* WPCore */
// @import "base/wpcore";

// /* Base */
@import "base/typography";
@import "base/base";
@import "base/animation";


// /* Layout */
@import "layout/header";
@import	"layout/sections";
@import "layout/footer";
@import "layout/navigation";

@import "modules/card";
@import "modules/cols";
@import "modules/scroll";
@import "modules/section";
@import "modules/slider";
@import "modules/popup";
@import "modules/preloader";
@import "modules/home_slider";

// /* Login */
@import "pages/login";

// /* Pages */
@import "pages/approach";
@import "pages/contributor";
@import "pages/company";
@import "pages/contact";
@import "pages/home";
@import "pages/news";
@import "pages/project";
@import "pages/legal";
@import "pages/404";
