.section {
    display: block;
    margin-bottom: 50px;
    @include r(1024) {
        margin-bottom: 0;
        width: auto;
        height: 100%;
        white-space: normal;
        display: inline-block;
        /*overflow-y: auto;*/
        overflow: hidden;
        vertical-align: top;
        word-spacing: 0;
        position: relative;
		z-index: 1;
    }
    &:last-child {
        margin-bottom: 0;
    }
}

.js .animate-go.fade-in {
    position: relative;
    transform: translate3d(0, 0, 0);
    opacity: .2;
    transition: opacity 1s cubic-bezier(.59, .01, .28, 1), transform 1s cubic-bezier(.59, .01, .28, 1), -webkit-transform 1s cubic-bezier(.59, .01, .28, 1);
    transition-delay: 0s;
    @include r(1024) {
    }
}

.js .animate-go.animated {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    will-change: opacity;
}


.section__inner {
    position: relative;
    flex: 1;
    @include r(1024) {
        display: flex;
        height: 100%;
    }
}

.section__inner--valign {
    @include r(1024) {
        align-items: center;
    }
}

.section__content {
    padding: 0 $mobile-padding $mobile-padding;
    @include r(1024) {
        padding: 90px 10vmin;
    }
}

.section__content--fullheight {
    @include r(1024) {
        height: 100%;
    }
}

.section__content--wide {
    width: 100%;
    @include r(1024) {
        padding: 90px 45px;
        height: 100%;
    }
}

@include r(1024) {
    .section--50 {
        width: 50%;
    }
    .section--70 {
        width: 70%;
    }
    .section--100 {
        width: 100%;
    }
}

.section--pink {
    background: $pink;
    color: $black;
    .section__category {
        border-color: $black;
    }
    .section__logo svg {
        fill: $black;
    }
}

.section--green {
    background: $green;
    color: $black;
    .section__category {
        border-color: $black;
    }
    .section__logo svg {
        fill: $black;
    }
}

.section--blue {
    background: $blue;
    color: $black;
    .section__category {
        border-color: $black;
    }
    .section__logo svg {
        fill: $black;
    }
}

/* Common Section Components */

.section__title {
    font-family: $fontface4;
    font-size: 14vmin;
    font-weight: normal;
    letter-spacing: -.02em;
    line-height: 1;
    margin-bottom: 10px;

    @include r(400) {
        font-size: 14vmin;
    }

    @include r(1024) {
        font-size: 11vmin;
        white-space: nowrap;
        height: auto;
        .home & {
            font-size: 9vmin;
        }
    }
    @include r(1280) {
        // font-size: 9vmin;
    }
}

.section__category {
    font-family: $fontface3;
    font-size: 13px;
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: .075em;
    border-top: 1px solid #fff;
    padding-top: 25px;
    margin-bottom: 5px;
}

.section__highlight {
    @extend .contributor__name;
}

.section__text {
    margin-bottom: 30px;
    h3 {
        font-family: $fontface2;
        font-weight: normal;
    }
    ul {
        list-style: none;
    }
    li {
        padding-left: 0;
        &:before {
            content: '-';
            display: inline-block;
            margin-right: 5px;
        }
    }
}


/* Section - Logo */

.section-logo {
    padding: $mobile-padding;
    margin-bottom: 120px;
    overflow: hidden;
    @include r(1024) {
        background: transparent;
        position: fixed;
        z-index: 2;
        left: 45px;
        top: 0;
        border: 0;
        padding: 0;
        height: 90px;
        display: flex;
        align-items: center;
        margin-bottom: 0;
        .home & {
            left: 6vw;
            top: 6vw;
            height: auto;
        }
    }
}

.section__logo {
    @extend .logo;
    svg {
        fill: $white;
        transition: all .5s ease-in-out;
    }
   
    &:hover svg {
        @include r(1024) {
            /*fill: $white;*/
        }
    }
    .logo__full {
        display: block;
    }
    .logo__text {
        display: none;
    }
    @include r(1024) {
        .logo__text {
            display: block;
        }
        .logo__full {
            display: none;
        }
        .home & {
            .logo__text {
                display: none;
            }
            .logo__full {
                display: block;
            }
        }
    }
}
body.logo--default .section__logo svg {
    fill: $black;
}
body.logo--green .section__logo svg {
    fill: $green;
}
body.logo--blue .section__logo svg {
    fill: $blue;
}
body.logo--pink .section__logo svg {
    fill: $pink;
}

/* Section - Intro */

.section__intro {
    font-family: $fontface1;
    font-style: italic;
	letter-spacing: -.02em;
	font-size: 5vmin;
	line-height: 1.25;
    @include r(400) {
        font-size: 3.75vmin;
    }
    @include r(1024) {
        font-size: 18px;
        line-height: 1.4;
    }
}

.section-intro,
.project-intro {
    /*min-height: 40vh;*/
    margin-top: 50px;
    display: flex;
    align-items: center;
    margin-bottom: 100px;
    @include r(1024) {
        display: inline-block;
        margin-top: 0;
        min-height: 0;
        max-width: 65vmin;
        margin-bottom: 0;
    }
    .section__inner {
        @include r(1024) {
            display: table;
        }
    }
    .section__content {
        @include r(1024) {
            // padding-right: 13vw;
            display: table-cell;
            vertical-align: middle;
        }
    }
}
.project-intro {
    @include r(1024) {
        max-width: none;
    }
}




/* Section - Text Image */

.section-text-image {}


/* Section - Next */

.section-next {
    text-align: center;
    @include r(1024) {
        text-align: left;
        .section__content {
            flex: 1;
            padding-right: 0;
            padding-left: 13vw;
        }
    }
}

.section-next__link {}

.section-next__title {
    font-family: $fontface4;
    font-size: 14vmin;
    letter-spacing: -.02em;
    line-height: 1;
    display: block;
    position: relative;
    margin-bottom: 10px;
    @include r(400) {
        font-size: 6.5vmin;
    }
    @include r(1024) {
        font-size: 60px;
        padding-right: 90px;
        padding-bottom: 20px;
        margin-bottom: 20px;
        
        &:before,
        &:after {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            height: 1px;
            width: 100%;
            bottom: 0px;
            transition: width .5s ease; 
        }
        &:before {
            opacity: .15;
        }
        &:after {
            width: 0%;
        }
        
        
        .section-next__link:hover &:after {
            width: 100%;
        }
    }
}

.section-next__description {
    font-style: italic;
	letter-spacing: -.01em;
	font-size: 5vmin;
	line-height: 1.25;
    
    display: block;
    padding-bottom: 150px;
    @include r(400) {
        font-size: 3.75vmin;
    }
    @include r(1024) {
        padding-right: 90px;
        padding-bottom: 0;
        font-size: 18px;
        line-height: 1.4;
    }
    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate3d(-50%, 0, 0);
        width: 1px;
        height: 150px;
        background: $gray-dark;
        @include r(1024) {
            display: none;
        }
    }
}


.section-contributor-profile, 
.section-article {
    width: 100%;
    &:last-child {
        padding-bottom: 60px;
        @include r(1024) {
            margin-bottom: 0;
            padding-bottom: 0;
        }
    }
}

.section-anchor {
    
    @include r(1024) {
        position: absolute;
        left: calc(5vw + 30px);
        top: 0;
        width: 0;
        height: 0;
        left: calc(5vw + 30px);
    }
}
