/* Columns */
.col__inner {
    padding: $mobile-padding;
    @include r(1024) {
        padding: 50px;
    }
}
.col--bg {
    background-size: cover;
}

.cols--bordered {
    @include r(1024) {
        height: 100%;
    }
    .col {
        border: 1px solid $white;
        border-bottom-width: 0;
        
        &:last-child {
            border-bottom-width: 1px;
        }
        @include r(1024) {
            /*display: flex;*/
            border-width: 1px;
            & + .col {
                margin-left: -1px;
            }
        }
    }
}
.col--bg {
    margin-bottom: 10px;
}

@include r(1024) {
    .cols {
        display: flex;
        height: 100%;
    }
    .col {
        /*flex: 1;*/
        display: flex;
        position: relative;
        flex-direction: column;
        height: 100%;
    }
    .col-25 {
        width: 25vw;
    }
    .col-33 {
        width: 33vw;
    }
    .col-50 {
        width: 50vw;
    }
    .col-66 {
        width: 66vw;
    }
    .col-100 {
        width: 100vw;
    }
    .col--bg {
        margin-bottom: 0;
    }
    .col-image {
        height: calc(100vh - 2 * 90px);
        width: auto;
        max-width: none;
    }
    .col--pad {
        margin-right: 30px;
    }
    .col--narrow {
        width: 280px;
        flex-basis: 280px;
        flex-grow: 0;
    }
    .col--slider {
        flex: 0;
    }
}
