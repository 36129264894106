.logo {
	margin: 0;
	width: 100px;
	height: auto;
	@include r(1024) {
		width: 124px;
	}
}
.header__logo {
	@extend .logo;
	display: none;
}
.header__logo svg,
.header__logo--white svg { fill: $white; }
.header__logo--black svg { fill: $black; }
.header__logo--green svg  { fill: $green; }
.header__logo--pink svg  { fill: $pink; }


.post-edit-link {
	background: $white;
	color: $black;
	position: fixed;
	z-index: 10;
	top: 100px;
	right: 0;
	width: 60px;
	height: 60px;
	line-height: 60px;
	text-align: center;
}

