
#preloader {
	width: 100%;
	height: 100%;
	
	position: absolute;
	z-index: 50;
	will-change: transform;
	pointer-events: none;

	background: $gray-dark;
}

#loader-anim {
	display: none;
	width: 80px;
	height: 100px;

	top: 0px;
	bottom: 0px;
	right: 0px;
	left: 0px;
	margin: auto;
	position: absolute;
	opacity: 0;
}
.preload-logo {
	#loader-anim {
		display: block;
	}
	#preloader {
		background-color: $gray-dark;
	}
}


/* Leg 1 */
#loader-anim img {
	max-width: initial;
    display: block;
    position: absolute;
}
.leg-frame {
	position: absolute;
}

#pl-leg1, #pl-leg2, #pl-leg3 {
	position: absolute;
	overflow: hidden;
}

#pl-leg1 {
	width: 78px;
	height: 58px;
}

#pl-leg1 .p--diag1 {
	position: absolute;
	width: 84px;
	height: 2px;
	display: block;
	transform-origin: 0% 0%;
	transform: rotate(23deg);
	top: 0;
	left: 0;
}

.p--vert {
	position: absolute;
	width: 2px;
	height: 24px;
	display: block;
	-webkit-transform-origin: 0% 0%;
	-moz-transform-origin: 0% 0%;
	-ms-transform-origin: 0% 0%;
	-o-transform-origin: 0% 0%;
	transform-origin: 0% 0%;
}
#pl-leg1 .leg-frame .p--diag1:nth-child(1) {  }
#pl-leg1 .leg-frame .p--diag1:nth-child(2) { top: 24px; }
#pl-leg1 .leg-frame .p--vert:nth-child(3) { top: 34px;	left: 75px; }

#pl-leg1 > .p--vert:nth-child(1) {  top: 2px; }
#pl-leg1 > .p--vert:nth-child(2) {  top: 4px;	left: 5px; }
#pl-leg1 > .p--vert:nth-child(3) {  top: 6px;	left: 10px; }
#pl-leg1 > .p--vert:nth-child(4) {  top: 8px;	left: 15px; }
#pl-leg1 > .p--vert:nth-child(5) {  top: 10px;	left: 20px; }
#pl-leg1 > .p--vert:nth-child(6) {  top: 12px;	left: 25px; }
#pl-leg1 > .p--vert:nth-child(7) {  top: 14px;	left: 30px; }
#pl-leg1 > .p--vert:nth-child(8) {  top: 16px;	left: 35px; }
#pl-leg1 > .p--vert:nth-child(9) {  top: 18px;	left: 40px; }
#pl-leg1 > .p--vert:nth-child(10) { top: 21px;	left: 45px; }
#pl-leg1 > .p--vert:nth-child(11) { top: 23px;	left: 50px; }
#pl-leg1 > .p--vert:nth-child(12) { top: 25px;	left: 55px; }
#pl-leg1 > .p--vert:nth-child(13) { top: 27px;	left: 60px; }
#pl-leg1 > .p--vert:nth-child(14) { top: 29px;	left: 65px; }
#pl-leg1 > .p--vert:nth-child(15) { top: 31px;	left: 70px; }

/* Leg 2 */
#pl-leg2 {
	width: 77px;
	height: 57px;
    top: 32px;
    left: 0px;
}

#pl-leg2 .p--diag2 {
	position: absolute;
	width: 77px;
	height: 2px;
	display: block;
	transform-origin: 100% 100%;
	transform: skewY(-21deg);
	top: 0;
	left: 0;
}

#pl-leg2 .leg-frame .p--diag2:nth-child(2) { top: 25px; }
#pl-leg2 .leg-frame .p--vert:nth-child(3) { top: 31px; }

#pl-leg2 > .p--diag2:nth-child(1) { top: 5px; }
#pl-leg2 > .p--diag2:nth-child(2) { top: 10px; }
#pl-leg2 > .p--diag2:nth-child(3) { top: 15px; }
#pl-leg2 > .p--diag2:nth-child(4) { top: 20px; }

/* Leg 3 */
#pl-leg3 {
	width: 78px;
	height: 40px;
    top: 62px;
    left: 0px;
}
#pl-leg3 .p--diag3 {
	position: absolute;
	width: 31px;
	height: 2px;
	display: block;
	transform-origin: 0% 0%;
	transform: skewY(23deg);
	top: 0;
	left: 0;
}
#pl-leg3 .leg-frame .p--diag3:nth-child(2) { top: 25px; }
#pl-leg3 .leg-frame .p--vert:nth-child(3) {	top: 14px;	left: 29px; }

#pl-leg3 > .p--diag3:nth-child(1) { top: 5px; }
#pl-leg3 > .p--diag3:nth-child(2) { top: 10px; }
#pl-leg3 > .p--diag3:nth-child(3) { top: 15px; }
#pl-leg3 > .p--diag3:nth-child(4) { top: 20px; }



.p--vert,
.p--diag1,
.p--diag2,
.p--diag3 {
	background: $white;
}


.theme--green,
.single-post  {
	#preloader {
		background: $green;
	}
	.p--vert, .p--diag1, .p--diag2, .p--diag3 {
		background: $gray-dark;
	}
}
.theme--blue,
.contact {
	#preloader {
		background: $blue;
	}
	.p--vert, .p--diag1, .p--diag2, .p--diag3 {
		background: $gray-dark;
	}
}
.theme--pink {
	#preloader {
		background: $pink;
	}
	.p--vert, .p--diag1, .p--diag2, .p--diag3 {
		background: $gray-dark;
	}
}
