// Placeholder for vertical cards in 'Approach' and 'News' page

@mixin card-theme($themecolor) {
    color: $themecolor;
    border-color: shade($themecolor, 60%);
    &:hover {
        @include r(1024) {
            color: $black;
            background: $themecolor;
            z-index: 2;
            cursor: pointer;
        }
        
        .contributor__link,
        .article__link,
        .section--dark & .contributor__link,
        .section--dark & .article__link  {
            @include r(1024) {
                color: $black;
            }
        }
    }
    .contributor__link-holder {
        background: $themecolor;
        color: $black;

        @include r(1024) {
            background: none;
            color: $themecolor;
        }
    }
    .contributor__link,
    .article__link {
        transition: color .5s ease-in-out, background .5s ease-in-out;
        position: relative;
        color: $black;
        &:after {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            height: 1px;
            width: 100%;
            bottom: -6px;
            background: $themecolor;
            opacity: .3;
        }
        @include r(1024) {
            color: $themecolor;
        }

        .section--dark & {
            color: $black;
            @include r(1024) {
                color: $themecolor;
            }
        }
    }
}


%card-vertical {
	border: 1px solid;
	margin-bottom: 10px;
	transition: color .5s ease-in-out, background .5s ease-in-out;
    transition-delay: 0;
	display: block;
	position: relative;
	z-index: 1;
    transform: translateZ(0);
    @include r(1024) {
    	margin-bottom: 0;
        width: 350px;
        display: flex;
        flex: 1 0 350px;
        flex-direction: column;
        /*border-right-width: 0;*/
        overflow: hidden;
        margin-right: -1px;
    }
    &:last-child {
    	/*border-right-width: 1px;*/
    }

    &.card--blue {
        @include card-theme($blue);
    }
    &.card--green {
        @include card-theme($green);
    }
    &.card--pink {
        @include card-theme($pink);
    }
}



// Placeholder for interor card in 'Contributor' and 'News' page
%card-details {
    color: $black;
    .section-logo {
        margin-bottom: 0;
        svg {
            fill: $black;
        }
    }
    .cols--bordered .col {
        /* border-color: #91a7ad; */
    }
    .col--left {
        transition: all .5s ease-in-out;

        @include r(1024) {
            display: flex;
            flex-direction: column;
            flex: 0 0 350px;
        }

    }
    .col--middle {
        @include r(1024) {
            flex: 1;
        }
    }
    .col--right {
        /* background: #acc9d0; */
        @include r(1024) {
            max-width: 350px;
            flex: 0 0 25%;
        }
    }
}

// Placeholder for card links
%card__link-holder {
    margin-top:auto;
    text-align: center;
    padding: 13px 10px;
    background: $gray-dark;
    color: $white;
    transform: translateZ(0);
    @include r(1024) {
        padding: 45px 50px;
        background: none;
    }
    @media screen and (min-width: 1024px) and (max-height: 770px) {
        padding: 4vmin 50px;
    }
}
%card__link {
    font-family: $fontface3;
    font-size: 15px;
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: .1em;
    color: $white;
    @include r(1024) {
        font-size: 15px;

    }
    &:before {
        background: none;
        @include r(1024) {
        }
    }
}


// Related column on contributor and news profile page
.related {
    border-bottom: 1px solid;
    text-align: center;
    
    .single-post & {
        border-color: #a4b1a0;
    }
    &:last-child {
        border-bottom: none;
    }
    .related__title {
        margin-bottom: 20px;
    }
    .col--right & {
        padding-top: 50px;
        padding-bottom: 50px;
    }
}
.related__title {
     @extend .contributor__title;
}
.related__firm {
    font-size: 20px;
    margin-bottom: 10px;
}
.related__source {
    font-size: 6.5vmin;
    margin-bottom: 10px;
    line-height: 1.2;
    @include r(1024) {
        font-size: 18px;
    }
}

.related-project {
    display: block;
    margin-bottom: 30px;
    &:last-child {
        margin-bottom: 0;
    }
}
.related__thumb {
    padding-bottom: 50%;
    overflow: hidden;
    background-size: cover;
    position: relative;
    &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background: $black;
        opacity: 0;
        transition: opacity .5s ease-in-out;
    }
    
    &:hover:after {
        @include r(1024) {
            opacity: .3;
        }
    }
}
.related__link,
.related__name {
    font-family: $fontface3;
    font-size: 15px;
    letter-spacing: .075em;
    text-transform: uppercase;
    color: $black;
    &:hover {
        @include r(1024) {
            color: $black;
        }
    }
}
.related__thumb {
    margin-bottom: 20px;
}

