$themecolor: $green;

.articles {
	/*height: 100%;*/
	flex: 1;
}
.card-article {
	@extend %card-vertical;
}

/*.article__info {
	padding: $mobile-padding;
	p {
		margin-top: 1em;
	}
	@include r(1024) {
		padding: 50px 50px 10px;
		flex-grow: 1;
	}
}*/
.article__info {
	@extend .contributor__info;
}

.article__image {
	width: auto;
    display: block;
    margin: 0 auto;
    border-radius: 50%;
    max-width: 100%;
    @include r(400) {
		max-width: 36vmax;
	}
    @include r(1024) {
    	max-height: 27vh;
    }
	
}
@media screen and (min-width: 1024px) and (max-height: 770px) {
	.news, 
	.single-post {
		.article__image {
			display: none;
		}
		.article__link-holder {
			text-align: left;
		}
		.article__title {
			font-size: 4vmin;
		}
	}
}
.article__image-holder {
	padding: 0 25px;
	margin-bottom: 35px;
	@include r(1024) {
		margin-bottom: 0;
	}
}

.article__link-holder {
	@extend %card__link-holder;
}

.article__link {
	@extend %card__link;
	color: $themecolor;
	&:before {
		@include r(1024) {
			background: $themecolor;
		}
	}
}

.single-post .article__link {
	@include r(1024) {
		color: $black;
	}
	&:before {
		@include r(1024) {
			background: $gray-dark;
		}
	}
}

.news {
	.article__link-holder {
		background: $themecolor;
		@include r(1024) {
			background: none;
		}
	}
	.article__link {
		color: $black;
		@include r(1024) {
			color: $themecolor;
		}
	}
}

.article__date {
	@extend .contributor__title;
}
.article__title {
	@extend .contributor__name;
}
.article__label {
	@extend .contributor__label;
}
.article__publisher {

}

.article__content {
	@extend .contributor__bio;
	h2, h3, h4, b {
		@extend .contributor__label;
		margin-bottom: .35em;
	}
}
.single-post {
	@extend %card-details;
	background: $themecolor;
	.cols--bordered .col {
        border-color: shade($themecolor, 20%);
    }
    .col--left {
    	padding-bottom: 40px;
    	@include r(1024) {
    		overflow: auto;
    		padding-bottom: 0;
    	}
    	/*&:hover {
    		@include r(1024) {
    			color: $themecolor;
    			.article__link {
    				color: $themecolor;
    			}
    		}
    	}*/
    	.article__link-holder {
    		display: none;
    		@include r(1024) {
    			display: block;
    		}
    	}
    }
    .col--right {
		background: #c4d8be; /* better use shade($themecolor, 15%); */
		.article__link-holder {
			@include r(1024) {
				display: none;
			}
		}
	}
}


.more-articles {
	color: $black;
	font-size: 12px;
	font-family: $fontface2;
	letter-spacing: .05em;
	text-transform: uppercase;
	background: $themecolor;
	margin: 100px auto $mobile-padding;
	transition: all .5s ease-in-out;
	will-change: transform;
	position: relative;
	z-index: 1;
	width: 20vmin;
	height: 20vmin;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
    @include r(1024) {
    	width: auto;
    	height: auto;
    	border-radius: 0;
    	border: 2px solid $themecolor;
    	margin: 0 0 0 -1px;
    	padding: 20px;
    	color: $themecolor;
    	background: none;
    	flex: 0;
    }
    
	&:hover {
	    @include r(1024) {
	    	color: $black;
	    	z-index: 2;
	    	cursor: pointer;
	    	background: $themecolor;
	    }
	}
	&.loading:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		border-radius: 50%;
		border: 2px solid rgba($white, 1);
		border-top-color:rgba(255, 255, 255, 0);
		border-bottom-color:rgba(255, 255, 255, 0);
		transform-origin: center;
		@include animation('spin 2s linear 0ms infinite');
		@include r(1024) {
			border: 0;
		}
	}
	&__label {
		position: relative;
		text-align: center;
		@include r(1024) {
			.loading &:before {
				content: '';
				position: absolute;
				width: 20px;
				height: 20px;
				left: 7px;
				top: -28px;
				border: 2px solid rgba($white, .5);
				border-top: 2px solid rgba(255, 255, 255, 0);
				border-radius: 50%;
				@include animation('spin 2s linear 0ms infinite');
			}
		}
	}
}




.link {
	position: fixed;
	z-index: 1;
	bottom: 0;
	width: 50%;
	color: $white;
	transition: all .6s cubic-bezier(0.77, 0, 0.175, 1);
	transition-delay: 0;
	@include r(1024) {
		width: auto;
		height: 100px;
		top: 50%;
		transform: translateY(-50%);
	}
	&.link--enter  {
		transition-delay: .5s;
	}
	&--prev {
		left: 0;
		@include r(1024) {
			left: -150px;
			
			.preload & {
				left: -300px;
			}
		}
	}

	&--next {
		right: 0;
		@include r(1024) {
			right: -150px;
		}

		.preload & {
			right: -300px;
		}
	}

	&__top {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		position: relative;
		z-index: 2;
	}
	&__bottom {
		@include r(1024) {
			width: 250px;
			padding: 15px 10px;
			position: absolute;
			z-index: 1;
			bottom: 0;
			left: 0;
			background: rgba($black, .8);
			transform: translateY(90%);
			transition: all .3s ease-in;
			opacity: 0;
			transition-delay: .3s;

			.link--prev & {
				right: 0;
				left: auto;
				text-align: right;
			}
		}
	}
	&__image {
		display: none;
		@include r(1024) {
			display: block;
			width: 150px;
			height: 100px;
			background: $black;
			background-size: cover;
			background-position: center center;
			.link--next & {
				order: 2;
			}
		}
	}
	&__arrow {
		display: flex;
		align-content: center;
		justify-content: center;
		height: 54px;
		width: 100%;
		background: $black;
		@include r(1024) {
			width: 45px;
			height: 100px;
			transition: all .6s ease-in;
			.link--next & {
				order: 1;
			}
		}
		svg {
			width: 24px;
			fill: $white;
		}
		.link--prev & {
			border-right: 1px solid rgba(255, 255, 255, .3);
			@include r(1024) {
				border: 0;
			}
			svg {
				transform: rotate(180deg);
			}
		}
	}
	&__title {
		display: none;
		@include r(1024) {
			display: block;
			font-size: 18px;
			line-height: 1.35;
			margin-bottom: 8px;
			.link--prev & {
				padding-left: 50px;
			}
			.link--next & {
				padding-right: 50px;
			}
		}
	}
	&__subtitle {
		display: none;

		@include r(1024) {
			display: block;
			font-family: $fontface3;
			font-size: 13px;
			font-weight: normal;
			text-transform: uppercase;
			letter-spacing: .075em;
		}
	}

	@include r(1024) {
		&:hover {
			transition-delay: 0s;
			.link__arrow {
				width: 100px;
			}
			.link__bottom {
				transform: translateY(100%);
				opacity: 1;
			}
		}
	}
	&--prev:hover {
		@include r(1024) {
			transition-delay: 0s;
			left:0;
		}
	}
	&--next:hover {
		@include r(1024) {
			transition-delay: 0s;
			right:0;
		}
	}
}


.link-back {
	display: none;
	
	@include r(1024) {
		display: flex;
		bottom: 0;
		left: 45px;
		position: fixed;
		z-index: 2;
		// width: 200px;
		height: 90px;
		align-content: center;
		justify-content: center;
		cursor: pointer;
	}
	svg {
		display: block;
		width: 24px;
		opacity: .5;
		margin: auto;
		margin-right: 10px;
		transition: all .3s ease-in-out;
	}
	&__label {
		display: block;
		flex: 1;
		font-family: $fontface3;
		font-size: 15px;
		letter-spacing: .075em;
		text-transform: uppercase;
		margin: auto;
		margin-right: 10px;
	}
	&:hover {
		svg {
			opacity: 1;
		}
	}
}

@include keyframes(spin) {
    0%     { transform: rotate(0); }
    100%   { transform: rotate(360deg) }
}
